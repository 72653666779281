<template>
  <div class="layout-brochure">
    <div class="content">
      <slot />
    </div>
    <div id="modal-portal-target" />
  </div>
</template>

<script setup lang="ts">
// Disable user zooming on the page
useHead({
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no",
    },
  ],
});
</script>

<style lang="scss" scoped>
.layout-brochure {
  display: grid;
  grid-template-rows: 100vh auto; // fallback for older browsers
  grid-template-rows: 100svh auto;

  .content {
    min-height: 100vh; // fallback
    min-height: 100svh;
  }
}
</style>
